import {ApolloQueryResult} from "@apollo/client";
import React, {useContext, useEffect, useMemo, useReducer} from "react";
import {AuthContext} from "./components/AuthProvider";
import {UserPreferences} from "./components/common/Constants";
import {useGetUserPrefLazyQuery} from "./generated/graphql";

export interface AppContextState {
  loaded: boolean;
  isTimeFormat24hr: boolean;
  isPresentationMode: boolean;
  isChatOpen: boolean;
  isAllAssignedStopsOpen: boolean;
  totalUnreadChannels: number;
  selectedDriverChannelId: string | undefined;
  selectedDriverUnreadCount: number;
  isMapPortalOpen: boolean;
  isGlobalFindOpen: boolean;
}

export type AppContextActions =
  | {type: "SetAppSettings"; payload: any}
  | {type: "SetIs24HrTimeFormat"; payload: boolean}
  | {type: "SetIsPresentationMode"; payload: boolean}
  | {type: "SetLoaded"; payload: boolean}
  | {type: "SetIsChatOpen"; payload: boolean}
  | {type: "SetIsAllAssignedStopsOpen"; payload: boolean}
  | {type: "SetTotalUnreadChannel"; payload: number}
  | {type: "SetSelectedDriverChannelId"; payload: string | undefined}
  | {type: "SetSelectedDriverUnreadCount"; payload: number}
  | {type: "ResetMessageState"}
  | {type: "SetOpenMapPortal"; payload: boolean}
  | {type: "SetIsGlobalFindOpen"; payload: boolean};

const initialState: AppContextState = {
  loaded: false,
  isTimeFormat24hr: false,
  isPresentationMode: false,
  isChatOpen: false,
  isAllAssignedStopsOpen: false,
  totalUnreadChannels: 0,
  selectedDriverChannelId: undefined,
  selectedDriverUnreadCount: 0,
  isMapPortalOpen: false,
  isGlobalFindOpen: false
};

interface IAppContext {
  appState: AppContextState;
  dispatch: React.Dispatch<AppContextActions>;
}

const AppContext = React.createContext<IAppContext>({
  appState: initialState,
  dispatch: () => {
    /**/
  }
});

const AppContextReducer = (state: AppContextState, action: AppContextActions): AppContextState => {
  switch (action.type) {
    case "SetIs24HrTimeFormat": {
      return {
        ...state,
        isTimeFormat24hr: action.payload
      };
    }
    case "SetIsPresentationMode": {
      return {
        ...state,
        isPresentationMode: action.payload
      };
    }
    case "SetLoaded": {
      return {
        ...state,
        loaded: action.payload
      };
    }
    case "SetAppSettings": {
      return action.payload;
    }
    case "SetIsChatOpen": {
      return {
        ...state,
        isChatOpen: action.payload
      };
    }
    case "SetIsAllAssignedStopsOpen": {
      return {
        ...state,
        isAllAssignedStopsOpen: action.payload
      };
    }

    case "SetTotalUnreadChannel": {
      return {...state, totalUnreadChannels: action.payload};
    }

    case "SetSelectedDriverUnreadCount": {
      return {...state, selectedDriverUnreadCount: action.payload};
    }

    case "SetSelectedDriverChannelId": {
      return {...state, selectedDriverChannelId: action.payload};
    }

    case "ResetMessageState": {
      return {...state, totalUnreadChannels: 0, selectedDriverChannelId: undefined, selectedDriverUnreadCount: 0};
    }

    case "SetOpenMapPortal": {
      return {
        ...state,
        isMapPortalOpen: action.payload
      };
    }
    case "SetIsGlobalFindOpen": {
      return {
        ...state,
        isGlobalFindOpen: action.payload
      };
    }
  }
};

type ApplicaitonContextProps = {
  children: JSX.Element[] | JSX.Element;
};

const AppContextProvider = ({children}: ApplicaitonContextProps) => {
  const [appState, dispatch] = useReducer(AppContextReducer, initialState as AppContextState);
  // TODO: need user pref search by key starting with
  const {user} = useContext(AuthContext);
  const [getUserPref] = useGetUserPrefLazyQuery();

  useEffect(() => {
    if (user != undefined) {
      getUserPref({variables: {name: UserPreferences.isTimeFormat24hr}})
        .then((x: ApolloQueryResult<any>) => {
          initialState.isTimeFormat24hr = x.data.getUserPref?.value === "true";
        })
        .catch((error: Error) => {
          console.error(error);
        })
        .finally(() => {
          dispatch({type: "SetLoaded", payload: true});
        });
    }
  }, [user, getUserPref]);

  const value = useMemo(() => ({appState, dispatch}), [appState, dispatch]);
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

export {AppContext, AppContextProvider};
