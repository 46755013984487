import React, {memo, useCallback, useRef, useState} from "react";
import styled from "@emotion/styled";
import ToolPanel from "./common/Toolpane";
import Grid, {IGridRef} from "./grid/Grid";
import Search from "../common/Search";
import {JobAssignmentViewState} from "../../views/JobAssignmentViewReducer";

interface IAssignedStopsProps {
  assignmentViewState: JobAssignmentViewState;
}

const AssignedStops = ({assignmentViewState}: IAssignedStopsProps) => {
  const gridRef = useRef<IGridRef>(null);
  const [gridHasActiveFilters, setGridHasActiveFilters] = useState<boolean>(false);

  const onToggleToolPane = (key: string) => {
    gridRef.current?.onToggleToolPane(key);
  };

  const onSearch = useCallback((query: string) => {
    return gridRef.current?.onSearch(query);
  }, []);

  const onSetGridHasActiveFilters = useCallback((value: boolean) => {
    setGridHasActiveFilters(value);
  }, []);

  return (
    <Container data-testid="assigned-stops-container">
      <Header>
        <Search
          onSearch={onSearch}
          isConstrainedByFilters={gridHasActiveFilters}
          idPrefix="assigned-stops"
          width="300px"
        />
        <ToolPanel onToggleToolPanel={onToggleToolPane} />
      </Header>
      <Body>
        <Grid
          ref={gridRef}
          assignmentViewState={assignmentViewState}
          setGridHasActiveFilters={onSetGridHasActiveFilters}
        />
      </Body>
    </Container>
  );
};

export default memo(AssignedStops);

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 1;
`;
